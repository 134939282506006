import { fromJS } from 'immutable';
import { createStore } from 'redux';

// Actions
export const types = {
  INITIAL: 'user/INITIAL',
  SIGN_IN: 'user/SIGN_IN',
  SIGN_OUT: 'user/SIGN_OUT',
  LAN: 'user/LAN',
  USER_STATUS: 'user/USER_STATUS',
  USER_LEVEL: 'user/USER_LEVEL',
  AGENT_SIGN_IN: 'agent/SIGN_IN',
  CHANGE_DEFAULT_MODULE_CONFIG: 'user/CHANGE_DEFAULT_MODULE_CONFIG',
  CHANGE_IS_UNREAD_NEWS: 'user/CHANGE_IS_UNREAD_NEWS',
  INITIAL_UPLOAD_STATUS: 'task/INITIAL_UPLOAD_STATUS',
  CHANGE_UPLOAD_STATUS: 'task/CHANGE_UPLOAD_STATUS',
};

// Action Creators
export const actions = {
  initial: (obj) => ({
    type: types.INITIAL,
    payload: obj,
  }),
  signIn: (obj) => ({
    type: types.SIGN_IN,
    payload: obj,
  }),
  signOut: () => ({
    type: types.SIGN_OUT,
  }),
  setLan: (lan) => ({
    type: types.LAN,
    payload: lan,
  }),
  setUserStatus: (obj) => ({
    type: types.USER_STATUS,
    payload: obj,
  }),
  agentSignIn: (obj) => ({
    type: types.AGENT_SIGN_IN,
    payload: obj,
  }),
  changeIsUnreadNews: (obj) => ({
    type: types.CHANGE_IS_UNREAD_NEWS,
    payload: obj,
  }),
  initialUploadStatus: () => ({
    type: types.INITIAL_UPLOAD_STATUS,
  }),
  changeUploadStatus: (obj) => ({
    type: types.CHANGE_UPLOAD_STATUS,
    payload: obj,
  }),
  changeDefaultModuleConfig: (obj) => ({
    type: types.CHANGE_DEFAULT_MODULE_CONFIG,
    payload: obj,
  }),
};

// Reducer
const initialState = {
  username: null,
  agentname: null,
  loginType: 'logout',
  lan: 'zh',
  isUnreadNews: false,
  expiryDate: null,
  obsTail: (new Date()).valueOf().toString(),
  isUploadingObj: {
    't1w.zip': false,
    't22d.zip': false,
    't23d.zip': false,
    'swi.zip': false,
    'cbf.zip': false,
    'dataf.zip': false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.INITIAL: return fromJS(state)
      .set('username', action.payload.username)
      .set('agentname', action.payload.agentname)
      .set('loginType', action.payload.loginType)
      .set('modules', action.payload.modules)
      .set('defaultModuleConfig', action.payload.defaultModuleConfig)
      .set('lan', action.payload.lan)
      .set('userStatus', action.payload.userStatus)
      .set('userLevel', action.payload.userLevel)
      .set('isUnreadNews', action.payload.isUnreadNews)
      .set('expiryDate', action.payload.expiryDate)
      .toJS();
    case types.SIGN_IN: return fromJS(state)
      .set('username', action.payload.username)
      .set('loginType', action.payload.loginType)
      .set('modules', action.payload.modules)
      .set('defaultModuleConfig', action.payload.defaultModuleConfig)
      .set('userStatus', action.payload.userStatus)
      .set('userLevel', action.payload.userLevel)
      .set('isUnreadNews', action.payload.isUnreadNews)
      .set('expiryDate', action.payload.expiryDate)
      .toJS();
    case types.AGENT_SIGN_IN: return fromJS(state)
      .set('agentname', action.payload.agentname)
      .set('loginType', action.payload.loginType)
      .toJS();
    case types.SIGN_OUT: return fromJS(state)
      .set('username', initialState.username)
      .set('agentname', initialState.agentname)
      .set('loginType', initialState.loginType)
      .toJS();
    case types.LAN: return fromJS(state)
      .set('lan', action.payload)
      .toJS();
    case types.USER_STATUS: return fromJS(state)
      .set('userStatus', action.payload)
      .toJS();
    case types.CHANGE_IS_UNREAD_NEWS: return fromJS(state)
      .set('isUnreadNews', action.payload)
      .toJS();
    case types.INITIAL_UPLOAD_STATUS: return fromJS(state)
      .set('obsTail', (new Date()).valueOf().toString())
      .set('isUploadingObj', initialState.isUploadingObj)
      .toJS();
    case types.CHANGE_UPLOAD_STATUS: {
      const newUO = { ...state.isUploadingObj };
      newUO[action.payload.fileType] = action.payload.value;
      return fromJS(state)
        .set('isUploadingObj', newUO)
        .toJS();
    }
    case types.CHANGE_DEFAULT_MODULE_CONFIG: return fromJS(state)
      .set('defaultModuleConfig', action.payload)
      .toJS();
    default: return state;
  }
}

export const store = createStore(reducer);
